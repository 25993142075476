import React from "react"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import PrivacyPolicy from "../../components/sections/legal/privacy-policy"
import Footer from "../..//components/sections/footer"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Navigation />
    <PrivacyPolicy />
    <Footer />
  </Layout>
)

export default PrivacyPolicyPage
