import React from "react"
import { Link } from "gatsby"

import { BasicPageContainer } from "../../global"

const PrivacyPolicy = () => (
  <BasicPageContainer>
    <h1>Website Privacy Policy</h1>
    <p>
      Welcome to the Dismissly website and application (the “Site”). The owner
      of the Site, Droplet, LLC, is committed to maintaining the privacy and
      security of your personal information. “Personal Information” means
      information that identifies you personally, including your name, physical
      address, email address, phone number, or credit card information. Personal
      Information does not include Student Data (as defined below). As part
      of our commitment to the privacy and security of your Personal
      Information, Droplet has developed this Privacy Policy to make you aware
      of our information gathering practices and how we use the information we
      receive.
    </p>
    <p>
      By using the Site, you signify your agreement to the terms of this privacy
      policy (the "Privacy Policy"). If you do not agree to this Privacy Policy,
      please do not use this Site. Droplet reserves the right, in its sole
      discretion, to modify, alter or otherwise update this Privacy Policy at
      any time. If Droplet makes such revisions, you will be notified via a
      notation that the Privacy Policy is new next to the link to the Privacy
      Policy. For major revisions as determined by Droplet in its sole
      discretion, if Droplet has your email address on file, Droplet will send
      you an email and notify you at least thirty (30) days in advance of the
      update. A notice of the last date of revision will always appear at the
      bottom of this Privacy Policy. Your continued use of the Site constitutes
      your consent to any updates to the Privacy Policy.
    </p>
    <p>
      Please also review the <Link to="/legal/terms-of-use">Terms of Use</Link>{" "}
      of this Site, to which the Privacy Policy forms a part. If you wish to
      make a purchase from the Site, please also review the{" "}
      <Link to="/legal/terms-of-license">Terms of License</Link>, as they set
      forth the terms for any purchase you make from the Site.
    </p>
    <h2>Gathering and Use of Information You Provide To Us</h2>
    <p>
      You can visit and browse this Site without telling us who you are or
      revealing any Personal Information. If you choose only to look at the Site
      and do not wish to create or log into an account, obtain specific
      information about the Services (as defined in the{" "}
      <Link to="/legal/terms-of-license">Terms of License</Link>), or make any
      purchases, you need not provide any Personal Information.
    </p>
    <p>
      If you want to receive information from us about the Services, you will
      need to provide us with the following Personal Information: name, email
      address, phone number, and school or school district name. This
      information will be used to provide you the information you requested, and
      may be used for market research and other marketing purposes such as
      direct mailings, emailings, or social media advertising from Droplet.
    </p>
    <p>
      If you want to use the Services, you will need to create an account. When
      you create an account with us, we will ask you for the following Personal
      Information: first and last name, and Google account username or email
      address (which will function as your user name and login credential).
      Please see our <Link to="/legal/terms-of-use">Terms of Use</Link> for
      further information regarding the security of login credentials. This
      information may be used for various marketing and/or business purposes
      including, but not limited to, providing services you have requested,
      customizing your experience on the Site, resolving disputes,
      troubleshooting problems, enforcing our agreements with you, or market
      research and direct mailings, emailings, or social media advertising from
      Droplet. We will share your Personal Information within our corporate
      family and with our third-party vendors as necessary for these purposes.
    </p>
    <p>
      If you want to make a purchase from the Site using a credit card, and
      already have an account, we will collect additional Personal Information
      from you as required from our third party payment gateway,{" "}
      <a href="https://stripe.com/">Stripe</a>, in order to validate your credit
      card and authorize the dollar value of your purchase for payment.
      Information collected includes information regarding your credit card
      account, such as name, address, card number, and other information needed
      to process the transaction. This credit card information is used for
      verification of a valid credit card and authorization for the value of the
      requested purchase. Droplet does not have access to your credit card
      account information but may receive other information about you from
      Stripe. For more information, please refer to{" "}
      <a href="https://stripe.com/privacy">Stripe’s privacy policy</a>.
    </p>
    <h2>Student Data</h2>
    <p>
      Droplet does not wish to have access to any student data that is
      designated as an Education Record under the Family Educational Rights and
      Privacy Act and its implementing regulations (“FERPA”). The Site is
      deliberately designed to minimize the amount of student personally
      identifiable information collected (“Student Data”). If you have purchased
      a <Link to="/legal/terms-of-license">license</Link>, you should provide{" "}
      <em>only</em> information that has been properly designated Directory
      Information under FERPA.{" "}
      <strong>
        It is a violation of the Terms to provide any information about a
        student to Droplet, either through the Site or the Services or
        otherwise, that is not properly designated Directory Information under
        FERPA.
      </strong>{" "}
      Such violations are subject to the Indemnification terms of the{" "}
      <Link to="/legal/terms-of-use">Terms of Use</Link>. Student Data is not
      Personal Information under this Privacy Policy; Student Data is Your Data
      (as defined in the <Link to="/legal/terms-of-use">Terms of Use</Link>) and
      will not be used or shared in the same ways as Personal Information.
      Instead, our use of Student Data is governed by applicable laws and our
      agreements with our customers. If you are a parent and have questions
      about how your child’s data is provided to us or used by your school,
      please contact your child’s school.
    </p>
    <h2>Children</h2>
    <p>
      Protecting the privacy of children is very important to us. For that
      reason, our Site does not intentionally solicit or collect Personal
      Information from users under age 13. If you are under the age of 13, we
      ask that you do not submit any Personal Information to us and use this
      Site only under the supervision of a parent or guardian. If you are a
      parent or guardian and you believe your child has submitted Personal
      Information to us, please contact us at support@dismissly.com, and we will
      delete your child’s Personal Information from our records.
    </p>
    <h2>Gathering and Use of Information Automatically Collected</h2>
    <p>
      When you visit this Site, our Web servers may collect some information
      automatically, such as your Internet protocol address, the identity of
      your Internet Service Provider, your operating system, general location,
      and the time and date of your visit. This information is not linked to
      Personal Information. This information is used to compile aggregate
      statistics about the total number of daily visitors to our Site, the pages
      most frequently visited, how long visitors tend to stay at each page, and
      how visitors move through the Site. By collecting this information, we get
      a better understanding of your preferences so we can continually improve
      your online experience with us.
    </p>
    <h2>Cookies and Web Beacons; Do Not Track</h2>
    <p>
      The Site uses cookies, which are small text files that a website can send
      to a user’s browser for storage on the hard drive. Cookies can make use of
      the Web easier by saving and administering status, preferences, and other
      user information. The Site also may use Web beacons, which are small
      strings of code that deliver a graphic image on a Web page or in an email
      message for the purpose of transferring data. We may use cookies and Web
      beacons to assist the functionality of the Site, such as to route you
      through the Site, keep you logged in, keep track of what services you are
      ordering, customize your experiences on the Site, or to improve the Site.
    </p>
    <p>
      We may also use some cookies or web beacons owned and operated by third
      parties, such as analytics providers or apps that help our site to
      function. We may use third party tools including{" "}
      <a href="https://policies.google.com/privacy?hl=en-US">
        Google Analytics
      </a>
      ,{" "}
      <a href="https://support.google.com/tagmanager/answer/9323295?hl=en">
        Google Tag Manager
      </a>
      , the <a href="https://console.developers.google.com/">Google API</a>, and{" "}
      <a href="https://policies.google.com/technologies/ads?hl=en-US">
        Google Ads
      </a>
      . While we vet our service providers to ensure high standards of privacy
      and security, we do not control the privacy policies of those third
      parties and do not control how their cookies or web beacons operate;
      please refer to their respective privacy policies for further information.
    </p>
    <p>
      Most browsers are set to accept cookies, but you can change your browser
      setting to block cookies. Although disabling cookies will not interfere
      with your ability to access the Site, you will need to accept cookies to
      use certain services on the Site, including logging into your account to
      make purchases. You can also disable the ability of Web beacons to capture
      information by blocking cookies.
    </p>
    <p>
      The Site does not respond to Do Not Track signals issued by your browser.
      To learn more about your choices regarding online tracking, please visit
      these sites: http://www.aboutads.info/choices/ and the following sites
      regarding some common browsers:{" "}
      <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
        Internet Explorer
      </a>
      , <a href="https://support.apple.com/en-us/HT201265">Safari</a>,{" "}
      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
        Chrome
      </a>
      ,{" "}
      <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
        Firefox
      </a>
      ,{" "}
      <a href="http://www.opera.com/help/tutorials/security/cookies/">Opera</a>,{" "}
      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en-GB&oco=1">
        Android
      </a>
      , and{" "}
      <a href="http://windows.microsoft.com/en-us/windows7/block-enable-or-allow-cookies">
        Windows Phone
      </a>
      . However, if you choose to disable cookies, some features of the Site,
      including the Services, may not work properly.
    </p>
    <h2>Unsolicited Information</h2>
    <p>
      If you choose to use one of our contact email addresses to send us any
      information other than that listed above under “Information You Provide to
      Us” and “Information Automatically Collected,” we are not responsible for
      keeping that information private. Please see our{" "}
      <Link to="/legal/terms-of-use#confidential">Terms of Use</Link> for
      further information about our policies regarding unsolicited information.
    </p>
    <h2>Disclosure of Information We Collect</h2>
    <p>
      Droplet will disclose Personal Information 1) in accordance with this
      Privacy Policy; 2) in order to comply in the event that law requires a
      disclosure of information submitted to our Site, including when required
      by a warrant, subpoena or court order; or 3) when we believe in good faith
      that disclosure is necessary to prevent harm or injury, such as in the
      event of emergencies, fraud, claims, or other liability. Droplet does not
      sell, trade, or rent your personal information to outside parties. Droplet
      may share statistical information about our users, traffic patterns, and
      related Site information, in aggregate form, with third-party affiliates
      and vendors, but these statistics will include no Personal Information.
    </p>
    <h2>Your Choices Regarding Your Personal Information</h2>
    <p>
      If you do not wish to receive information and promotional material from
      us, you may select the appropriate “opt out” option each time we ask for
      your Personal Information. If you already receive email or direct mail
      communications from us and no longer wish to receive such communications,
      please contact us at support@dismissly.com to be removed from our emailing
      and direct mailing lists.
    </p>
    <h2>External Links</h2>
    <p>
      We do not endorse and are not responsible for the information practices or
      privacy policies of websites operated by others that may be linked to or
      from this Site. If you decide to access a third party’s website that may
      be linked to or from this Site, you should consult that website’s privacy
      policy.
    </p>
    <h2>Business Transition</h2>
    <p>
      The information covered by this Privacy Policy is an asset of Droplet and
      will become part of our normal business records. If Droplet or a
      substantial part of our business is sold or otherwise transferred to a
      third party, such as a legal successor in interest, your information may
      be transferred to that third party, subject to the Terms.
    </p>
    <h2>Updating Your Information</h2>
    <p>
      If any of the Personal Information you submitted to our Site is inaccurate
      or outdated or otherwise needs to be changed, please log into your account
      and correct it. If you are not able to do so through your account, please
      contact us at support@dismissly.com and we will use reasonable efforts in
      our control to revise that information.
    </p>
    <p>
      You may ask to have the information on your account deleted or removed;
      however, because we keep track of past transactions, you cannot delete
      information associated with past transactions on this Site. In addition,
      it may be impossible to completely delete your information without some
      residual information remaining because of backups.
    </p>
    <h2>How We Secure Your Information</h2>
    <p>
      This Site has industry-standard measures in place to protect the loss,
      misuse and alteration of the information under our control, including
      regular security checks. Personal Information you submit to our Site is
      stored for us by Google cloud-based storage products. For more information
      on Google’s information security practices, visit{" "}
      <a href="https://safety.google/security/">
        https://safety.google/security/
      </a>{" "}
      and{" "}
      <a href="https://cloud.google.com/security">
        https://cloud.google.com/security
      </a>
      . Unfortunately, no data transmission over the Internet or any wireless
      network can be guaranteed to be 100% secure. As a result, while we strive
      to protect your Personal Information, you acknowledge that: 1) there are
      privacy and security risks to the Internet which are beyond our control;
      2) the security, integrity and privacy of any and all information
      exchanged between you and us through the Site cannot be guaranteed; and 3)
      any information or data may be viewed or tampered with by a third party
      while in transit.
    </p>
    <p>
      However, these measures standing alone are not sufficient to ensure the
      security of your Personal Information. It is also important for you to
      guard against unauthorized access to your passwords and unauthorized use
      of your computer and other internet-connected devices.
    </p>
    <h2>Dispute Resolution</h2>
    <p>
      This Privacy Policy is governed by the dispute resolution terms of the{" "}
      <Link to="/legal/terms-of-use">Terms of Use</Link>.
    </p>
    <h2>Questions</h2>
    <p>
      If you have any questions regarding this Privacy Policy, please contact us
      at support@dismissly.com
    </p>
    <p>
      <strong>Last revised: October 13, 2020</strong>
    </p>
  </BasicPageContainer>
)

export default PrivacyPolicy
